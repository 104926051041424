import React, { useEffect, useState } from 'react'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Layout from '../components/layout'

import '../styles/home-styles.css' // Uses same styles from blog cards on home screen for publication list
import '../styles/common-styles.css'
import '../styles/music-styles.css'
import '../styles/layout-styles.css'

const arrangements = [
  {
    title: 'Heigh Priest for Tuba Jazz Ensemble',
    description:
      'High Priest was recorded, with Howard Johnson playing tuba, in December 1967. This recorded album, Tender Moments, was subsequently released in 1968 on the Blue Note label. Later in 1992 on the Turning Point record album, High Priest was recorded again featuring McCoy Tyner’s big band, which included Howard Johnson on tuba and as an arranger.',
    link: 'https://potenzamusic.com/product/high-priest-tuba-johnson/',
  },
  {
    title: 'Der Hirt auf dem Felsen for Tuba Duo and Piano',
    description:
      'Der Hirt auf dem Felsen, or The Shepherd on the Rock, is a lied originally for soprano, clarinet and piano by Franz Schubert. It was written for Pauline Anna Milder-Hauptmann, a friend of the composer’s, who requested a piece from Schubert that would allow her to show off her versatility as a performer. The lyrics are taken from two poets, Wilhelm Muller and August Varnhagen von Ense. This version arranges both the soprano and the clarinet parts for tubas.',
    link: 'https://potenzamusic.com/product/der-hirt-felsen-tuba/',
  },
  {
    title: 'First Suite in Eb for Brass Quintet',
    description:
      'Gustav Holst’s First Suite in Eb for Military Band is one of the most well-known and frequently played pieces for wind band. It was one of the first successful pieces written specifically for the relatively young ensemble. The original work was scored for nineteen instruments, and so has been greatly condensed in this arrangement while staying true to the underlying harmonies and melodies of the piece.',
    link: 'https://potenzamusic.com/product/first-suite-quintet/',
  },
  {
    title: 'Tournament Galop for Tuba-Euphonium Quartet',
    description:
      'Tournament Galop, originally for piano, is arranged here for tuba-euphonium quartet. Written as a showpiece for the composer, the frequent galloping figures remind listeners of horses racing, and is inspired by Jan Mazeppa, who was lashed to a horse that was sent galloping until the horse collapsed from exhaustion.',
    link: 'https://potenzamusic.com/product/tournament-galop-gottschalk/',
  },
  {
    title: 'The Queen of the Night for Tuba and Piano',
    description:
      'The Queen of the Night Aria is most frequently heard on flute as a popular recital piece, but it has been performed on nearly every instrument. It is perhaps the most well-known of all opera arias, with a memorable melody, virtuosic technique and high range.',
    link: 'https://potenzamusic.com/product/nozze-figaro-tuba/',
  },
  {
    title: 'Dido’s Lament',
    description:
      '"When I am laid in earth", or Dido’s Lament, is from Henry Purcell’s opera Dido and Aeneas. It is an extremely well-known aria that is used in many music history and theory text books as an example of basso ostinato. The lament is sung in the opera as Dido prepares for her death.',
    link: 'https://potenzamusic.com/product/dido-lament-tuba/',
  },
  {
    title: 'Chant du Menestral for Tuba and Piano',
    description:
      'Chant du Menestral, originally for cello and orchestra, remains one of Glazunov’s most popular works. With long phrases, the tuba plays the part of the minstrel, alternating in ABA form between the melancholy opening and the more upbeat middle section.',
    link: 'https://potenzamusic.com/product/chant-menestral-tuba/',
  },
]

const editorials = [
  {
    title: 'Howard Johnson - Testimony for Tuba Jazz Ensemble',
    description:
      'Testimony was written in 1991 by Howard Johnson for Gravity. Witness and testimony was first conceived for big band by Johnson and later adapted for Gravity. He often uses this piece for teaching musical concepts at high school and college educational residencies.',
    link: 'https://potenzamusic.com/product/testimony-tuba-johnson/',
  },
  {
    title: 'Howard Johnson - Little Black Lucille for Tuba Jazz Ensemble',
    description:
      'This composition is certainly a tribute to the life and struggles of Howard Johnson’s Aunt Lucille. The penny whistle opening and appearance near the end is a great tribute to the textural disjunction of the arrangement and the juxtaposition of the story that unfolds. He has composed a piece that is soulful, powerful, and triumphal.',
    link: 'https://potenzamusic.com/product/little-black-lucille-johnson/',
  },
  {
    title:
      'Howard Johnson - Workin’ Hard for the Joneses for Tuba Jazz Ensemble',
    description:
      'Nedra Johnson, Howard’s daughter, is a singer, guitarist, tuba player, and composer. She speaks of the Jones as another word for addiction. It is not “keeping up” with the Joneses. The piece represents all of the Jones or addictions – drug, sugar, money, alcohol and more. Naming them the way that she has in her piece can make you see it for the tragedy that it is, or laugh with the addicted, that simply wish to be comfortably numb.',
    link: 'https://potenzamusic.com/product/workin-hard-joneses-johnson/',
  },
  {
    title: 'Howard Johnson - Way Back Home for Tuba Jazz Ensemble',
    description:
      '"Way Back Home is the Jazz Crusader’s Anthem and although we recently lost Wilton Felder, we shall not forget his major contributions as a composer." The Jazz Crusaders were signed by Pacific Jazz Records around 1960. Wilton Felder, composer/tenor saxophonist, and Wayne Henderson, trombone, fronted the group. The Jazz Crusaders style had a very heavy emphasis on the rhythm and blues genre as well as gospel and soul.',
    link: 'https://potenzamusic.com/product/way-back-home-tuba-johnson/',
  },
  {
    title: 'Howard Johnson - Natural Women',
    description:
      'Carole King wrote this piece in 1967 for Aretha Franklin, the famed rhythm and blues gospel singer. Subsequently, "Natural Woman" was recorded that same year for Atlantic Records. Carole King did not perform this piece on her own until the 1970s. The song for King meant that she was not thinking about being famous, but that music was her normal and authentic life and that is what determined her to feel like a "Natural Woman".',
    link: 'https://potenzamusic.com/product/natural-woman-johnson/',
  },
  {
    title: 'Howard Johnson - Evolution for Tuba Jazz Ensemble',
    description:
      'Howard Johnson’s arrangement of Evolution was first performed in the early 1970s. Recordings of the arrangement with the original Gravity personnel have been lost. Bob Neloms is known for his active musical life as a post-bop jazz pianist in New York starting in the late 1960s. The story has it that many years later when Howard played Evolution for Bob Neloms, Bob did not recall the composition. The evolution is that learning and recreating Bob Neloms’ chart was monumental for Howard. He continued to use this composition to build his knowledge of jazz harmony and to develop and sharpen his improvisational skills.',
    link: 'https://potenzamusic.com/product/evolution-tuba-johnson/',
  },
  {
    title: 'Howard Johnson - Fly With The Wind for Tuba Jazz Ensemble',
    description:
      'Fly with the Wind was originally recorded in 1976. The album featuring this title track was McCoy Tyner’s first all-strings project with flute, oboe and his rhythm section featuring McCoy Tyner, piano, Ron Carter, bass, and Billy Cobham, drums. Later in 1992, The Turning Point album was released on the Birdology label with McCoy Tyner’s Big Band, featuring Howard as both a tubist and an arranger.',
    link: 'https://potenzamusic.com/product/fly-wind-tuba-johnson/',
  },
  {
    title: 'Howard Johnson - Big Alice for Tuba Jazz Ensemble',
    description:
      'When Howard heard Don Pullen’s chart, he immediately wanted to arrange the piece for Gravity to play. During a rehearsal when he brought the arrangement to the band, he called Don Pullen and the ensemble played the arrangement on Pullen’s telephone answering machine. When Don Pullen heard the musical message, he immediately called Howard and exclaimed that "he loved it!"',
    link: 'https://potenzamusic.com/product/big-alice-johnson/',
  },
]

const PublicationsPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  const [tab, setCurrentTab] = useState(0)

  const [arrangementCards, setArrangementCards] = useState([])
  const [editorialCards, setEditorialCards] = useState([])

  // Is mobile query
  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab)
  }

  useEffect(() => {
    const arrangementsTemp = []
    const editorialsTemp = []

    arrangements.forEach((post) => {
      arrangementsTemp.push(
        <Card
          className='mx-auto my-4 w-11/12 md:w-8/12 xl:w-6/12'
          variant='outlined'
          onClick={() => window.open(post.link, '_blank')}
        >
          <CardActionArea>
            <CardContent>
              <p className='text-2xl my-4 text-title'>{post.title}</p>
              <p className='"my-4 text-gray-700'>{post.description}</p>
            </CardContent>
          </CardActionArea>
          <CardActions className='text-center md:text-left'>
            <Button className='blogDate text-gray-700'>
              <span style={{ color: '#0070f3' }}>Purchase</span>
            </Button>
          </CardActions>
        </Card>
      )
    })

    editorials.forEach((post) => {
      editorialsTemp.push(
        <Card
          className='mx-auto my-4 w-11/12 md:w-8/12 xl:w-6/12'
          variant='outlined'
          onClick={() => window.open(post.link, '_blank')}
        >
          <CardActionArea>
            <CardContent>
              <p className='text-2xl my-4'>{post.title}</p>
              <p className='"my-4 text-gray-700'>{post.description}</p>
            </CardContent>
          </CardActionArea>
          <CardActions className='text-center md:text-left'>
            <Button className='blogDate'>
              <span style={{ color: '#0070f3' }}>Purchase</span>
            </Button>
          </CardActions>
        </Card>
      )
    })

    setEditorialCards(editorialsTemp)
    setArrangementCards(arrangementsTemp)
  }, [isMobile, setArrangementCards, setEditorialCards])

  return (
    <Layout>
      <div className='pageTitle'>Publications</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      <Paper
        variant='outlined'
        square
        className='flex justify-center mx-auto w-11/12 md:w-8/12 xl:w-6/12'
      >
        <Tabs
          value={tab}
          indicatorColor='primary'
          textColor='primary'
          onChange={handleTabChange}
          aria-label='disabled tabs example'
        >
          <Tab label='Arrangements' value={0} />
          <Tab label='Editorials' value={1} />
        </Tabs>
      </Paper>
      {tab === 0 && <>{arrangementCards}</>}
      {tab === 1 && <>{editorialCards}</>}
    </Layout>
  )
}

export default PublicationsPage
